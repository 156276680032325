import alterpressApi from './base';

import {
    ISurveyAnswer,
    ISurveyAnswersMutation,
    ISurveyQuestion,
    TSurvey,
} from '../../models/survey.model';

export const surveyApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['SURVEY'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getSurveyQuestions: builder.query<
                TSurvey,
                { clientId?: number | string | null; withoutAnswered?: boolean }
            >({
                query: ({ clientId, withoutAnswered }) =>
                    `survey/questions${getGetSurveyQuestionsParams(clientId, withoutAnswered)}`,
                providesTags: ['SURVEY'],
            }),
            getSurveyAnswers: builder.query<ISurveyAnswer[], void>({
                query: () => `survey/answers`,
                providesTags: ['SURVEY'],
            }),
            getClientQuestions: builder.mutation<
                ISurveyQuestion[],
                { clientId?: number | string | null; withoutAnswered?: boolean }
            >({
                query: ({ clientId, withoutAnswered }) => ({
                    url: `survey/questions${getGetSurveyQuestionsParams(
                        clientId,
                        withoutAnswered
                    )}`,
                    method: 'GET',
                }),
            }),
            sendSurveyAnswers: builder.mutation<void, ISurveyAnswersMutation>({
                query: (data) => ({
                    url: `survey/send-answers`,
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: ['SURVEY'],
            }),
        }),
    });

function getGetSurveyQuestionsParams(
    clientId: number | string | undefined | null,
    withoutAnswered: boolean | undefined
) {
    if (!clientId && !withoutAnswered) return '';
    let params = '?';
    if (clientId) {
        params += `clientId=${clientId}`;
    }
    if (withoutAnswered) {
        params += `&withoutAnswered`;
    }
    return params;
}

export const {
    useGetSurveyQuestionsQuery,
    useGetSurveyAnswersQuery,
    useGetClientQuestionsMutation,
    useSendSurveyAnswersMutation,
} = surveyApi;
