import alterpressApi from './base';

import { ICreateOrderMutation, ICreateOrderResponse, IOrder } from '../../models/order.model';

export const orderApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['Order'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getOrders: builder.query<IOrder[], void>({
                query: () => 'account/orders?expand=orderStatus,items,addresses,payments,delivery',
                providesTags: ['Order'],
            }),
            getOrder: builder.query<IOrder, string | undefined | null>({
                query: (uuid) =>
                    `orders/${uuid}?expand=media,orderStatus,items,items.product,items.product.media,items.variant,items.variant.media,items.variant,addresses,payments,delivery,items.variant.sets`,
                providesTags: ['Order'],
            }),
            createOrder: builder.mutation<ICreateOrderResponse, ICreateOrderMutation>({
                query: (data) => ({
                    url: `orders?expand=payments`,
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: ['Order'],
            }),
            retryPayment: builder.mutation<any, string>({
                query: (paymentId) => ({
                    url: `orders/payments/${paymentId}/retry`,
                    method: 'GET',
                }),
            }),
        }),
    });

export const {
    useGetOrderQuery,
    useGetOrdersQuery,
    useCreateOrderMutation,
    useRetryPaymentMutation,
} = orderApi;
