import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { IModal } from '../../models/modal.model';

export type TModalsState = IModal[];

const initialState: TModalsState = [];

const modalsSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        addModal: (state, { payload }: PayloadAction<Omit<IModal, 'modalId' | 'pathname'>>) => {
            const modalId = new Date().getTime();
            const pathname = window.location.pathname;
            const modal = { ...payload, modalId, pathname };
            return [...state, modal];
        },
        removeModal: (state, { payload }: PayloadAction<number>) => {
            return state.filter((modal) => modal.modalId !== payload);
        },
    },
});

export default modalsSlice.reducer;

export const { addModal, removeModal } = modalsSlice.actions;

export const selectModalsState = (state: RootState) => state.modals;
