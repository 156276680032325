import React from 'react';
import { Script, GatsbyBrowser } from 'gatsby';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';

import './src/styles/reset.scss';
import './src/styles/variables.scss';
import './src/styles/global.scss';
import store from './src/redux/store';

const API_URL = process.env.API_URL;
const queryClient = new QueryClient();

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ prevLocation, location }) => {
    if (location.pathname === prevLocation?.pathname) return;
    sessionStorage.setItem(
        'prevPathname',
        prevLocation?.pathname ? `${prevLocation.pathname}${prevLocation.search}` : ''
    );
    window.dispatchEvent(new Event('sessionStorage'));
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>
            <Script src="/cookies.js" data-api-url={`${API_URL}/cookies`} />
        </Provider>
    );
};

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
    pathname,
    prevRouterProps,
    routerProps,
}) => {
    const prevLocation = prevRouterProps?.location;
    const { location } = routerProps;

    if (
        (location.hash && !prevLocation) ||
        (location.hash && prevLocation?.pathname !== location.pathname)
    ) {
        setTimeout(() => {
            const elementId = location.hash.substring(1);
            document.getElementById(`${elementId}`)?.scrollIntoView(true);
        }, 100);
        return false;
    }

    const prevPathname = prevRouterProps?.location.pathname;
    const preventScroll = routerProps.location.state?.preventScroll;
    return !(prevPathname === pathname && preventScroll);
};
