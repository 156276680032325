import { createListenerMiddleware, isAnyOf, isRejectedWithValue } from '@reduxjs/toolkit';

import { orderApi } from './api/order';
import { getUserTokenData } from '../utils/get-user-token-data';
import { forceUserLogout } from '../utils/force-user-logout';

export const listener = createListenerMiddleware();

listener.startListening({
    matcher: isAnyOf(orderApi.endpoints.createOrder.matchFulfilled),
    effect: ({ payload }) => {
        const redirectUrl = payload.payments[0]?.redirectUrl;
        if (redirectUrl) {
            window.location.href = redirectUrl;
        }
    },
});

// listens for the situation when there is a user token in localStorage and the error status is 401
// this means a situation in which the user's session has ended and the user needs to be logged out
listener.startListening({
    matcher: isRejectedWithValue,
    effect: ({ payload }: { payload: unknown }) => {
        const tokenData = getUserTokenData();
        if (
            payload &&
            typeof payload === 'object' &&
            'status' in payload &&
            payload.status === 401 &&
            tokenData
        ) {
            forceUserLogout();
        }
    },
});
