import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface IAccessibilityState {
    fontSizeMultiplier: number;
    lineHeightMultiplier: number;
    letterSpacingMultiplier: number;
    wordSpacingMultiplier: number;
    paragraphMarginMultiplier: number;
    contrastMode: number;
}

const initialState: IAccessibilityState = getInitialStateFromLocalStorage();

function getInitialStateFromLocalStorage(): IAccessibilityState {
    if (typeof window === 'undefined')
        return {
            fontSizeMultiplier: 1,
            lineHeightMultiplier: 1,
            letterSpacingMultiplier: 1,
            wordSpacingMultiplier: 1,
            paragraphMarginMultiplier: 1,
            contrastMode: 0,
        };
    return {
        fontSizeMultiplier: Number(localStorage.getItem('--fsm')) || 1,
        lineHeightMultiplier: Number(localStorage.getItem('--lhm')) || 1,
        letterSpacingMultiplier: Number(localStorage.getItem('--lsm')) || 1,
        wordSpacingMultiplier: Number(localStorage.getItem('--wsm')) || 1,
        paragraphMarginMultiplier: Number(localStorage.getItem('--pmm')) || 1,
        contrastMode: Number(localStorage.getItem('--cts')) || 0,
    };
}

const accessibilitySlice = createSlice({
    name: 'accessibility',
    initialState,
    reducers: {
        updateFontSize: (state, { payload }: PayloadAction<number>) => {
            localStorage.setItem('--fsm', payload.toString());
            state.fontSizeMultiplier = payload;
        },
        updateLineHeight: (state, { payload }: PayloadAction<number>) => {
            localStorage.setItem('--lhm', payload.toString());
            state.lineHeightMultiplier = payload;
        },
        updateLetterSpacing: (state, { payload }: PayloadAction<number>) => {
            localStorage.setItem('--lsm', payload.toString());
            state.letterSpacingMultiplier = payload;
        },
        updateWordSpacing: (state, { payload }: PayloadAction<number>) => {
            localStorage.setItem('--wsm', payload.toString());
            state.wordSpacingMultiplier = payload;
        },
        updateParagraphMargin: (state, { payload }: PayloadAction<number>) => {
            localStorage.setItem('--pmm', payload.toString());
            state.paragraphMarginMultiplier = payload;
        },
        updateContrast: (state, { payload }: PayloadAction<number>) => {
            localStorage.setItem('--cts', payload.toString());
            state.contrastMode = payload;
        },
    },
});

export default accessibilitySlice.reducer;

export const {
    updateFontSize,
    updateLineHeight,
    updateLetterSpacing,
    updateWordSpacing,
    updateParagraphMargin,
    updateContrast,
} = accessibilitySlice.actions;

export const selectAccessibilityState = (state: RootState) => state.accessibility;
