import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { clientApi } from '../api/client';
import { RootState } from '../store';
import { IClient } from '../../models/client.model';
import { forceUserLogout } from '../../utils/force-user-logout';

export interface IClientState {
    data: IClient | null;
}

const initialState: IClientState = {
    data: null,
};

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(
                clientApi.endpoints.loginClient.matchFulfilled,
                clientApi.endpoints.getClient.matchFulfilled,
                clientApi.endpoints.registerClient.matchFulfilled,
                clientApi.endpoints.updateClient.matchFulfilled
            ),
            (state, action) => {
                const { payload } = action;
                if (payload.token) {
                    state.data = payload;
                    localStorage.setItem(
                        'tokenData',
                        JSON.stringify({ ...payload.token, userType: 'client' })
                    );
                }
            }
        );
        builder.addMatcher(clientApi.endpoints.logoutClient.matchFulfilled, () => {
            forceUserLogout();
        });
    },
});

export default clientSlice.reducer;

export const selectClientState = (state: RootState) => state.client;
