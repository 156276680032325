import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import alterpressApi from './api';
import { listener } from './listener';
import surveySlice from './slices/survey.slice';
import clientSlice from './slices/client.slice';
import candidateSlice from './slices/candidate.slice';
import modalsSlice from './slices/modals.slice';
import accessibilitySlice from './slices/accessibility.slice';
import jobOfferLocalizationSlice from './slices/job-offer-localization.slice';

const store = configureStore({
    reducer: {
        [alterpressApi.reducerPath]: alterpressApi.reducer,
        accessibility: accessibilitySlice,
        candidate: candidateSlice,
        client: clientSlice,
        jobOfferLocalization: jobOfferLocalizationSlice,
        modals: modalsSlice,
        survey: surveySlice,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat(alterpressApi.middleware).prepend(listener.middleware);
    },
});
setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
