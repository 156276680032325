import alterpressApi from './base';

import { TLoginFormInitialValues } from '../../formik/login.form';
import { IChangePasswordValues } from '../../formik/change-password.form';
import {
    IClient,
    IClientContactCandidatesMutation,
    IClientRegisterMutation,
    IClientUpdateMutation,
} from '../../models/client.model';
import { ICandidateData } from '../../models/candidate-data.model';
import { IClientDashboard } from '../../models/client-dashboard.model';

export const clientApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['CLIENT'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            loginClient: builder.mutation<IClient, TLoginFormInitialValues>({
                query: (data) => ({
                    method: 'POST',
                    url: `login-client`,
                    body: data,
                }),
            }),
            registerClient: builder.mutation<IClient, IClientRegisterMutation>({
                query: (data) => ({
                    method: 'POST',
                    url: 'clients',
                    body: data,
                }),
            }),
            getClient: builder.query<IClient, void>({
                query: () => `client`,
            }),
            getSuggestedCandidate: builder.query<ICandidateData, number | string | undefined>({
                query: (id) => `candidates/${id}`,
            }),
            updateClient: builder.mutation<IClient, IClientUpdateMutation>({
                query: (data) => ({
                    method: 'PATCH',
                    url: `client`,
                    body: data,
                }),
            }),
            logoutClient: builder.mutation<void, void>({
                query: () => ({
                    method: 'POST',
                    url: 'logout',
                }),
            }),
            clientDashboard: builder.query<IClientDashboard, void>({
                query: () => ({
                    method: 'GET',
                    url: `client/dashboard`,
                }),
            }),
            clientPasswordChange: builder.mutation<void, IChangePasswordValues>({
                query: (data) => ({
                    method: 'POST',
                    url: 'client/change-password',
                    body: data,
                }),
            }),
            clientContactCandidates: builder.mutation<void, IClientContactCandidatesMutation>({
                query: (data) => ({
                    method: 'POST',
                    url: 'clients/contact-candidate',
                    body: data,
                }),
            }),
        }),
    });

export const {
    useGetClientQuery,
    useGetSuggestedCandidateQuery,
    useLoginClientMutation,
    useLogoutClientMutation,
    useRegisterClientMutation,
    useUpdateClientMutation,
    useClientDashboardQuery,
    useClientPasswordChangeMutation,
    useClientContactCandidatesMutation,
} = clientApi;
