import alterpressApi from './base';

import { TLoginFormInitialValues } from '../../formik/login.form';
import { TCandidateVerificationValues } from '../../formik/candidate-verification.form';
import { IChangePasswordValues } from '../../formik/change-password.form';
import { ICandidate } from '../../models/candidate.model';
import { ICandidateDataValues } from '../../formik/candidate-data.form';

export const candidateApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['CANDIDATE', 'CANDIDATE_AVAILABLE_CODES'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            loginCandidate: builder.mutation<ICandidate, TLoginFormInitialValues>({
                query: (data) => ({
                    method: 'POST',
                    url: `login-candidate`,
                    body: data,
                }),
            }),
            registerCandidate: builder.mutation<
                ICandidate,
                ICandidateDataValues & { advertisementId?: number | string }
            >({
                query: (data) => ({
                    method: 'POST',
                    url: 'candidates',
                    body: data,
                }),
            }),
            getCandidate: builder.query<ICandidate, void>({
                query: () => `candidate`,
                providesTags: ['CANDIDATE'],
            }),
            candidateDashboard: builder.query<any, any>({
                query: () => ({
                    method: 'GET',
                    url: `candidate/dashboard`,
                }),
            }),
            candidateRecommendation: builder.query<any, any>({
                query: () => ({
                    method: 'GET',
                    url: `candidates/recommendation`,
                }),
            }),
            verifyCandidate: builder.mutation<void, TCandidateVerificationValues>({
                query: (data) => ({
                    method: 'POST',
                    url: `candidates/verify`,
                    body: data,
                }),
            }),
            resendVerificationCode: builder.mutation<void, void>({
                query: () => ({
                    method: 'POST',
                    url: `candidates/resend-verification-code`,
                }),
                invalidatesTags: ['CANDIDATE_AVAILABLE_CODES'],
            }),
            getAvailableNumberOfVerificationCodes: builder.query<number, void>({
                query: () => `candidates/available-verification-codes`,
                providesTags: ['CANDIDATE_AVAILABLE_CODES'],
            }),
            updateCandidate: builder.mutation<ICandidate, ICandidateDataValues>({
                query: (data) => ({
                    method: 'PATCH',
                    url: `candidates`,
                    body: data,
                }),
            }),
            logoutCandidate: builder.mutation<void, void>({
                query: () => ({
                    method: 'POST',
                    url: 'logout',
                }),
            }),
            createBryqInterview: builder.mutation<{ assessmentUrl: string }, void>({
                query: () => ({
                    method: 'POST',
                    url: 'bryq/interview',
                }),
            }),
            candidatePasswordChange: builder.mutation<void, IChangePasswordValues>({
                query: (data) => ({
                    method: 'POST',
                    url: 'candidates/change-password',
                    body: data,
                }),
            }),
        }),
    });

export const {
    useGetCandidateQuery,
    useGetAvailableNumberOfVerificationCodesQuery,
    useCandidateDashboardQuery,
    useCandidateRecommendationQuery,
    useLoginCandidateMutation,
    useRegisterCandidateMutation,
    useLogoutCandidateMutation,
    useUpdateCandidateMutation,
    useVerifyCandidateMutation,
    useResendVerificationCodeMutation,
    useCreateBryqInterviewMutation,
    useCandidatePasswordChangeMutation,
} = candidateApi;
