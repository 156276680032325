import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export interface IJobOfferLocalizationState {
    isJobOfferPage: boolean;
    relatedPaths: Record<string, string> | null;
}

const initialState: IJobOfferLocalizationState = {
    isJobOfferPage: false,
    relatedPaths: null,
};

const jobOfferLocalizationSlice = createSlice({
    name: 'jobOfferLocalization',
    initialState,
    reducers: {
        setJobOfferLocalizationState: (
            state,
            { payload }: PayloadAction<IJobOfferLocalizationState>
        ) => {
            state.isJobOfferPage = payload.isJobOfferPage;
            state.relatedPaths = payload.relatedPaths;
        },
    },
});

export default jobOfferLocalizationSlice.reducer;

export const { setJobOfferLocalizationState } = jobOfferLocalizationSlice.actions;

export const selectJobOfferLocalizationState = (state: RootState) => state.jobOfferLocalization;
