import { createSlice } from '@reduxjs/toolkit';

import { surveyApi } from '../api/survey';
import { RootState } from '../store';
import { ISurveyAnswer, ISurveyQuestion } from '../../models/survey.model';

export interface ISurveyState {
    answers: ISurveyAnswer[];
    questions: ISurveyQuestion[];
}

const initialState: ISurveyState = {
    answers: [],
    questions: [],
};

const surveySlice = createSlice({
    name: 'survey',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            surveyApi.endpoints.getSurveyQuestions.matchFulfilled,
            (state, action) => {
                const { payload } = action;
                state.questions = payload;
            }
        );
        builder.addMatcher(surveyApi.endpoints.getSurveyAnswers.matchFulfilled, (state, action) => {
            const { payload } = action;
            state.answers = payload;
        });
    },
});

export default surveySlice.reducer;

export const selectSurveyState = (state: RootState) => state.survey;
