import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { candidateApi } from '../api/candidate';
import { RootState } from '../store';
import { ICandidate } from '../../models/candidate.model';

export interface ICandidateState {
    data: ICandidate | null;
    usedCodes: number | null;
    remainingCodes: number | null;
}

const initialState: ICandidateState = {
    data: null,
    usedCodes: null,
    remainingCodes: null,
};

const CODES_COUNT = 3;

const candidateSlice = createSlice({
    name: 'candidate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            isAnyOf(
                candidateApi.endpoints.loginCandidate.matchFulfilled,
                candidateApi.endpoints.getCandidate.matchFulfilled,
                candidateApi.endpoints.registerCandidate.matchFulfilled,
                candidateApi.endpoints.updateCandidate.matchFulfilled
            ),
            (state, action) => {
                const { payload } = action;
                state.data = payload;
                if (payload.token) {
                    localStorage.setItem(
                        'tokenData',
                        JSON.stringify({ ...payload.token, userType: 'candidate' })
                    );
                }
            }
        );
        builder.addMatcher(candidateApi.endpoints.logoutCandidate.matchFulfilled, () => {
            localStorage.removeItem('tokenData');
            window.location.reload();
        });
        builder.addMatcher(
            candidateApi.endpoints.getAvailableNumberOfVerificationCodes.matchFulfilled,
            (state, action) => {
                const { payload } = action;
                state.remainingCodes = payload;
                state.usedCodes = CODES_COUNT - payload;
            }
        );
    },
});

export default candidateSlice.reducer;

export const selectCandidateState = (state: RootState) => state.candidate;
